/*
 * @Author: HaoJG
 * @Date: 2021-08-09 10:48:34
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-03-23 17:14:48
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import staticRoutes from './staticRoute'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: staticRoutes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 路由跳转前验证
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (!sessionStorage.getItem('token') && to.meta.login == 1){
    sessionStorage.setItem(window.host+'/'+window.app+'frompath',to.path)
    next({ path: "/login", replace: true })
    NProgress.done()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()// 结束Progress
})

export default router
