/*
 * @Author: minihorse
 * @Date: 2020-12-09 15:30:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-24 10:00:40
 * 
 */
const Layout = () => import('../pages/layout')
// const Mobile = () => import('../pages/layout/mindex')
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },    
    {
        path: '/login',
        component: () => import(/* webpackChunkName:'second' */ '../pages/login/index.vue')
    },
    {
        path: '/menu',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '菜单列表',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/menu/list')
            },
        ]
    },
    {
        path: '/operator',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '操作员列表',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/list')
            },
            {
                path: 'group',
                meta: {
                    title: '操作员组',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/group')
            },
        ]
    },
    {
        path: '/setting',
        component: Layout,
        children: [
            { 
                path: 'list',
                meta: {
                    title: '参数设置',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/list')
            },
            { 
                path: 'banner',
                meta: {
                    title: 'banner设置',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/banner')
            },
            { 
                path: 'article/:type',
                meta: {
                    title: '用户协议/隐私政策',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/article')
            },
        ]
    },
    {
        path: '/record',
        component: Layout,
        children: [
            { 
                path: 'list',
                meta: {
                    title: '工作记录',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/record/list')
            },
            { 
                path: 'statistics',
                meta: {
                    title: '工作量统计',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/record/statistics')
            },
            { 
                path: 'special',
                meta: {
                    title: '特殊记录',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/record/special')
            },
            { 
                path: 'maintenance',
                meta: {
                    title: '维护记录',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/record/maintenance')
            },
        ]
    },
    {
        path: '/task',
        component: Layout,
        children: [
            { 
                path: 'list',
                meta: {
                    title: '工单列表',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/task/list')
            },
            { 
                path: 'add',
                meta: {
                    title: '添加工单',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/task/add')
            },
            { 
                path: 'add/:id',
                meta: {
                    title: '修改工单',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/task/add')
            },
            { 
                path: 'sign',
                meta: {
                    title: '程序谈单',
                    login:1,
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/task/sign')
            },
        ]
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path:'*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]
export default staticRoutes