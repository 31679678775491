// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'

import {
  Loading
} from 'element-ui'

let loadingInstance = null

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$qs = qs

const $axios = axios.create({
  baseURL: window.host + '/' + window.app,
  method: 'post',
  timeout: 10000, // 请求超时时间
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  showInfo: 0,
  showLoading: 0
});
$axios.interceptors.request.use(
  config => {
    config.data = config.data ? config.data : {}
    let url = config.url;
    if (config.method == 'get') {
      if (config.data) {
        url = url + '?';
        for (const propName of Object.keys(config.data)) {
          const value = config.data[propName];
          var part = encodeURIComponent(propName) + "=";
          if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
              for (const key of Object.keys(value)) {
                if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                  let data = propName + '[' + key + ']';
                  let subPart = encodeURIComponent(data) + '=';
                  url += subPart + encodeURIComponent(value[key]) + '&';
                }
              }
            } else {
              url += part + encodeURIComponent(value) + "&";
            }
          }
        }
        url = url.slice(0, -1);
        config.data = {};
      }
    }
    if (config.method == 'post') {
      // if(!config.data) config.data = {};
      var data = qs.parse(config.data)
      data = qs.stringify({
        token: sessionStorage.getItem('token'),
        ...data
      })
      config.data = qs.parse(data)
    }
    config.url = url;
    if (config.showLoading == 1) {
      loadingInstance = Loading.service()
    }
    return config;
  },
  error => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// 响应拦截器
$axios.interceptors.response.use(
  response => {
    if (response.config.showLoading == 1) loadingInstance.close();
    if (response.data.token) {
      sessionStorage.setItem('token', response.data.token)
    }
    switch (response.data.status) {
      case 1:
        if (response.config.showInfo == 1) {
          ElementUI.Message.success({ message: response.data.info })
        }
        return response.data;
      case 0:
        ElementUI.Message.error({ message: response.data.info })
        return response.data;
      case 9:
        ElementUI.Message.error({ message: response.data.info })
        sessionStorage.removeItem(window.host + '/' + window.app + 'token');
        router.push('/')
        break
      default:
        return response.data
      // ElementUI.Message.error({ message: '未知错误' })
      // break;
    }
  },
  error => {
    ElementUI.Message.error({ message: error.response.data.message })
  }
);


axios.defaults.withCredentials = true

// Vue.prototype.$axios = axios
Vue.prototype.$axios = $axios
Vue.prototype.app = window.app
Vue.prototype.webname = window.webName
Vue.prototype.HOST = window.host + '/' + window.app
Vue.prototype.picHost = window.host
Vue.prototype.pagesize = 10
Vue.prototype.pagesizes = ['10', '20', '50', '100', '1000']
Vue.prototype.formLabelWidth = '120px'
Vue.prototype.$pic = function (url) {
  return this.picHost + url
}
Vue.prototype.$picList = function (arr) {
  let result = [];
  for (let item in arr) {
    result.push(this.picHost + arr[item]);
  }
  return result
}

Vue.prototype.goPath = function (url) {
  this.$router.push(url)
}

Vue.prototype.goBack = function () {
  this.$router.back()
}

Vue.prototype.getTerminal = function () {
  if (
    /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
      navigator.userAgent
    )
  ) {
    return "mobile";
  } else {
    return "pc";
  }
}

Vue.prototype.getCommonInfo = function (options, callback) {
  this.$axios({
    url: '/index/getCommonInfo',
    data: {
      info: options
    },
    showInfo: 0
  }).then(res => {
    if (res.status == 1) {
      for (let item in options) {
        this[options[item]] = res[options[item]];
      }
      if (callback) callback();
    }
  })
}
Vue.prototype.showloading = function () {
  loadingInstance = Loading.service()
}
Vue.prototype.hideloading = function () {
  loadingInstance.close()
}
Vue.prototype.tempInfo = {}

Vue.prototype.saveInfo = function (infoName) {
  for (let item in this[infoName]) {
    this.tempInfo[item] = this[infoName][item]
  }
}
Vue.prototype.resetInfo = function (infoName) {
  for (let item in this[infoName]) {
    this[infoName][item] = this.tempInfo[item]
  }
}
Vue.prototype.getDate = function (str) {
  const date = new Date()
  let time
  let weekday
  let month
  switch (str) {
    case 'toweekend':
      weekday = date.getDay() || 7
      date.setDate(date.getDate() - weekday + 7)
      time = date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 23:59:59'
      break
    case 'premonth':
      month = date.getMonth() - 1;
      date.setMonth(month)
      month = month < 0 ? 11 : month;
      time =
        date.getFullYear() +
        '-' +
        (month + 1) +
        '-' +
        '01 00:00:00'
      break
    case 'premonthend':
      month = date.getMonth() - 1;
      date.setMonth(month)
      month = month < 0 ? 11 : month;
      time =
        date.getFullYear() +
        '-' +
        (month + 1) +
        '-' +
        new Date(date.getFullYear(), month, 0).getDate()
        +
        ' 00:00:00'
      break
    case 'today':
      time =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 00:00:00'
      break;
    case 'start':
      time =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        '01 00:00:00'
      break
    case 'toweek':
      weekday = date.getDay() || 7
      date.setDate(date.getDate() - weekday + 1)
      time = date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 00:00:00'
      break
    case 'crntyear':
      time = date.getFullYear() + '-01-01 00:00:00';
      break
    default:
      // 当天24点
      time =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 23:59:59'
  }
  return time
}

new Vue({
  render: h => h(App),
  router,
  store,
  axios
}).$mount('#app')
